<template>
  <div class="-mypage">
    <div class="c_main -bg-gray -pad-btm">
      <div class="inner">
        <nav class="c_pnkz">
          <ul class="c_pnkz_list">
            <li>
              <a href="/">ホーム</a>
            </li>
            <li>マイページ</li>
          </ul>
        </nav>
        <h1 class="c_main_ttl">マイページ</h1>
        <p class="greeting">
          <em>ようこそ</em>
          <span>{{ $auth.metadata.belongs }}</span>
          <strong>{{ $auth.metadata.fullname }}</strong> 様
        </p>
        <div class="warp">
          <div class="main">
            <section class="sec">
              <header class="sec_hd">
                <h2 class="sec_hd_ttl">あなたにおすすめのコンテンツ</h2>
                <a
                  class="c_txtlink -arrow sec_hd_link c_strong"
                  @click="$modal.show('recommend')"
                >興味・関心のあるキーワードを設定</a>
              </header>
              <div class="sec_list" style="display: block;">
                <a class="sec_item" v-for="c in recommends" :key="c.id" @click="clickContents(c)">
                  <figure class="sec_item_img">
                    <img
                      v-if="c.image_path"
                      :src="`https://${jc3LearningDomain}${c.image_path}`"
                      alt
                      width="65"
                      height="65"
                    />
                    <img
                      v-else
                      src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE"
                      alt
                      width="65"
                      height="65"
                    />
                  </figure>
                  <div class="sec_item_cnt">
                    <p class="sec_item_ttl">
                      <span>{{ c.category_name }}</span>
                      <em>{{ c.name }}</em>
                    </p>
                    <p class="c_tool_item_author">
                      <em>{{ c.author_name }}/{{ c.author_belonging }}</em>
                    </p>
                    <p class="sec_item_disc">{{ c.description }}</p>
                    <div class="c_tool_item_eval" v-if="c.type===8 || c.type===16">
                      <div class="c_tool_item_eval_star">
                        <star-rating :value="c.evaluation_stat.avg" :width="13" :height="13"></star-rating>
                      </div>
                      <span class="c_tool_item_eval_count">({{ c.evaluation_stat.count }})</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="sec_btn">
                <a class="c_btn -large" v-if="nextRecommend" @click="getNextRecommend">さらに表示</a>
              </div>
            </section>
            <section class="sec">
              <header class="sec_hd">
                <h2 class="sec_hd_ttl">利用履歴</h2>
              </header>
              <div class="sec_list">
                <a class="sec_item" v-for="c in histories" :key="c.id" @click="clickContents(c)">
                  <figure class="sec_item_img">
                    <img
                      v-if="c.image_path"
                      :src="`https://${jc3LearningDomain}${c.image_path}`"
                      alt
                      width="65"
                      height="65"
                    />
                    <img
                      v-else
                      src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE"
                      alt
                      width="65"
                      height="65"
                    />
                  </figure>
                  <div class="sec_item_cnt">
                    <p class="sec_item_ttl">
                      <span>{{ c.category_name }}</span>
                      <em>{{ c.name }}</em>
                    </p>
                    <p class="c_tool_item_author">
                      <em>{{ c.author_name }}/{{ c.author_belonging }}</em>
                    </p>
                    <p class="sec_item_disc">{{ c.description }}</p>
                    <div class="c_tool_item_eval" v-if="c.type===8 || c.type===16">
                      <div class="c_tool_item_eval_star">
                        <star-rating :value="c.evaluation_stat.avg" :width="13" :height="13"></star-rating>
                      </div>
                      <span class="c_tool_item_eval_count">({{ c.evaluation_stat.count }})</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="sec_btn">
                <a class="c_btn -large" v-if="nextHistory" @click="getNextHistory">さらに表示</a>
              </div>
            </section>
          </div>
          <div class="side">
            <div class="side_sec status">
              <div class="status_prof">
                <figure class="status_prof_img">
                  <img :src="profileImage" width="150" height="150" alt />
                </figure>
                <p class="status_prof_update">
                  <a class="c_txtlink -arrow" @click="$modal.show('profile')">プロフィール画像を変更する</a>
                </p>
              </div>
              <p class="status_msg">{{ profile.goal ? profile.goal : '目標未設定' }}</p>
              <p class="status_msg_update">
                <a class="c_txtlink -arrow" @click="$modal.show('goal')">目標を変更する</a>
              </p>
              <div class="status_data -first">
                <dl class="status_data_dl">
                  <dt>
                    <img :src="rankImg" width="30" height="34" alt />
                  </dt>
                  <dd>
                    あなたは
                    <em>{{ rank }}</em>です
                  </dd>
                </dl>
                <p class="status_data_about">
                  <a class="c_txtlink -arrow" @click="$router.push({name:'Batch'})">メンバーステージとは？</a>
                </p>
              </div>
              <div class="status_data">
                <dl class="status_data_dl">
                  <dt>
                    <img src="/img/cmn/img-side-cal@2x.png" width="31" height="26" alt />
                  </dt>
                  <dd>
                    利用日数
                    <em>{{ profile.login_count }}</em>日
                  </dd>
                </dl>
              </div>
            </div>
            <div class="side_sec char">
              <Character ref="character" :char="profile.character"></Character>
              <p class="char_update">
                <a class="c_txtlink -arrow" @click="$modal.show('character')">キャラクターを変更する</a>
              </p>
            </div>
            <div class="submit">
              <a class="submit_btn" @click="goMessage">
                事務局にメッセージを送る
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- プロフィール画像設定 -->
    <modal name="profile" adaptive height="auto" width="85%" :maxWidth="780">
      <section id="profile" class="c_popup">
        <h2 class="c_popup_ttl">プロフィール画像を変更する</h2>
        <p class="c_popup_lead">プロフィール画像を選択してください。推奨サイズ150×150px</p>
        <div class="c_popup_form">
          <input type="file" ref="file" @change="selectFile" />
        </div>
        <div class="c_popup_btns">
          <button class="c_btn" type="button" @click="setProfileImage">画像を変更</button>
        </div>
      </section>
    </modal>
    <!-- 目標設定 -->
    <modal name="goal" adaptive height="auto" width="85%" :maxWidth="789">
      <section id="goal" class="c_popup">
        <h2 class="c_popup_ttl">目標を変更する</h2>
        <p class="c_popup_lead">目標を変更できます。テキスト修正後、「目標を変更」をクリックしてください。推奨：30文字程度</p>
        <div class="c_popup_form">
          <input type="text" placeholder="例: 今秋、長官賞をとる！" v-model="newGoal" />
        </div>
        <div class="c_popup_btns">
          <button class="c_btn" type="button" @click="setGoal">目標を変更</button>
          <button class="c_btn -gray" type="button" @click="newGoal=''">クリア</button>
        </div>
      </section>
    </modal>
    <!-- キャラクター選択 -->
    <modal name="character" adaptive height="auto" width="85%" :maxWidth="868">
      <section id="character" class="c_popup">
        <h2 class="c_popup_ttl">キャラクターを変更する</h2>
        <p class="c_popup_lead">トップやマイページに表示するキャラクターをいつでも変更できます。</p>
        <div class="c_popup_char">
          <label class="c_popup_char_item">
            <figure class="c_popup_char_img">
              <img src="/img/mypage/img-char01@2x.png" width="162" height="162" alt />
            </figure>
            <div class="c_popup_char_neme">JC3ちゃん(おがわ)</div>
            <div class="c_popup_char_input">
              <input type="radio" name="char" value="0" v-model="checkChar" />
            </div>
          </label>
          <label class="c_popup_char_item">
            <figure class="c_popup_char_img">
              <img src="/img/mypage/img-char02@2x.png" width="162" height="162" alt />
            </figure>
            <div class="c_popup_char_neme">JC3ちゃん(いちご)</div>
            <div class="c_popup_char_input">
              <input type="radio" name="char" value="1" v-model="checkChar" />
            </div>
          </label>
          <label class="c_popup_char_item">
            <figure class="c_popup_char_img">
              <img src="/img/mypage/img-char03@2x.png" width="162" height="162" alt />
            </figure>
            <div class="c_popup_char_neme">JC3くん(じんぼ)</div>
            <div class="c_popup_char_input">
              <input type="radio" name="char" value="2" v-model="checkChar" />
            </div>
          </label>
          <label class="c_popup_char_item">
            <figure class="c_popup_char_img">
              <img src="/img/mypage/img-char04@2x.png" width="162" height="162" alt />
            </figure>
            <div class="c_popup_char_neme">JC3くん(あわじ)</div>
            <div class="c_popup_char_input">
              <input type="radio" name="char" value="3" v-model="checkChar" />
            </div>
          </label>
        </div>
        <div class="c_popup_btns">
          <button class="c_btn" type="button" @click="setCharacter">このキャラクターで確定</button>
        </div>
      </section>
    </modal>
    <!-- おすすめ表示設定 -->
    <modal name="recommend" adaptive height="auto" width="85%" :maxWidth="640">
      <section id="recommend" class="c_popup">
        <h2 class="c_popup_ttl">おすすめ表示を設定する</h2>
        <p class="c_popup_lead">
          トップやマイページに表示するおすすめコンテンツの表示を設定します。
          <br />気になるキーワードを選んでください（複数可）
          <br />選んだキーワードをもとにあなたへのおすすめコンテンツを表示します。
        </p>
        <ul class="c_popup_tag">
          <li v-for="tag in tags" :key="tag.id">
            <label>
              <input type="checkbox" :value="tag.id" v-model="checkedTags" />
              <em>{{ tag.text }}</em>
            </label>
          </li>
        </ul>
        <div class="c_popup_btns">
          <button class="c_btn" type="button" @click="setFavoriteTags">おすすめ表示を上記内容で設定する</button>
        </div>
      </section>
    </modal>
    <!-- アクセス制限 -->
    <modal name="unauthorized" adaptive height="auto" width="85%" :maxWidth="640">
      <section id="unauthorized" class="c_popup">
        <h2 class="c_popup_ttl">閲覧制限</h2>
        <p class="c_popup_lead">
          このコンテンツを閲覧する権限がありません。
          <br/>閲覧制限を解除するには、所属の管理者経由で警察庁にご連絡ください。
        </p>
        <div class="c_popup_btns">
          <button class="c_btn" type="button" @click="$modal.hide('unauthorized')">閉じる</button>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import Character from "@/components/Character";
import StarRating from "@/components/StarRating";
import { jc3LearningDomain } from "@/../config.json";
export default {
  components: {
    StarRating,
    Character
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
      profile: {
        goal: "",
        character: 0,
        login_count: 0,
        chat_id: '',
      },
      rank: "ブロンズ",
      rankImg: "/img/cmn/img-medal-beginner.png",

      newGoal: "",
      profileImage: "",
      selectedFile: undefined,

      tags: [],
      checkedTags: [],

      recommends: [],
      pageRecommend: 1,
      nextRecommend: null,

      histories: [],
      pageHistory: 1,
      nextHistory: null
    };
  },
  computed: {
    checkChar: {
      get() {
        return this.profile.character;
      },
      set(newValue) {
        this.profile.character = Number(newValue);
      }
    }
  },
  created() {
    this.fetchProfile();
    this.fetchProfileImage();
    this.fetchRecommend();
    this.fetchHistory();
    this.fetchTags();
    this.fetchFavoriteTags();
  },
  methods: {
    fetchRecommend() {
      this.$authAxios
        .get("/api/contents/recommend", {
          params: {
            page: this.pageRecommend
          }
        })
        .then(response => {
          this.recommends = this.recommends.concat(response.data.results);
          this.nextRecommend = response.data.next;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    },
    fetchHistory() {
      this.$authAxios
        .get("/api/contents/history", {
          params: {
            page: this.pageHistory
          }
        })
        .then(response => {
          this.histories = this.histories.concat(response.data.results);
          this.nextHistory = response.data.next;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    },
    fetchTags() {
      this.$authAxios
        .get("/api/tags")
        .then(response => {
          this.tags = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    },
    fetchFavoriteTags() {
      this.$authAxios
        .get("/api/profile/tags")
        .then(response => {
          this.checkedTags = response.data.map(o => o.tag_id);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    },
    fetchProfile() {
      this.$authAxios
        .get("/api/profile")
        .then(response => {
          this.profile = response.data;
          this.newGoal = response.data.goal;
          if (response.data.login_count >= 30) {
            this.rank = "マスター";
            this.rankImg = "/img/cmn/img-medal-master.png";
          } else if (response.data.login_count >= 14) {
            this.rank = "エキスパート";
            this.rankImg = "/img/cmn/img-medal-expert.png";
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$refs.character.character();
        });
    },
    fetchProfileImage() {
      this.$authAxios
        .get("/api/profile/image", { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type });
          this.profileImage = URL.createObjectURL(blob);
        })
        .catch(() => {
          this.profileImage = "/img/cmn/img-profile.jpg";
        });
    },
    setCharacter() {
      let form = { character: this.profile.character };
      this.$authAxios
        .patch("/api/profile", form)
        .then(response => {
          this.profile = response.data;
          this.$modal.hide("character");
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$refs.character.character();
        });
    },
    setFavoriteTags() {
      let form = { tags: this.checkedTags };
      this.$authAxios
        .post("/api/profile/tags", form)
        .then(() => {
          this.pageRecommend = 1;
          this.recommends = [];
          this.fetchRecommend();
          this.$modal.hide("recommend");
        })
        .catch(error => {
          console.log(error);
        });
    },
    setGoal() {
      let form = { goal: this.newGoal };
      this.$authAxios
        .patch("/api/profile", form)
        .then(response => {
          this.profile = response.data;
          this.$modal.hide("goal");
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectFile() {
      this.selectedFile = this.$refs.file.files[0];
    },
    setProfileImage() {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      this.$authAxios
        .post("/api/profile/image", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(() => {
          this.fetchProfileImage();
          this.$modal.hide("profile");
        })
        .catch(error => {
          console.log(error);
        });
    },
    getNextRecommend() {
      this.pageRecommend += 1;
      this.fetchRecommend();
    },
    getNextHistory() {
      this.pageHistory += 1;
      this.fetchHistory();
    },
    clickContents(contents) {
      if (this.$auth.scopes.includes('readonly:npa')) {
        if (contents.category_name === 'サイバー関連サービス等解説コンテンツ') {
          this.goContents(contents)
        } else {
          this.$modal.show('unauthorized')
        }
      } else {
        this.goContents(contents)
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`;
        window.open(url, "_blank");
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url;
        window.open(url, "_blank");
      } else if (contents.type === 8) {
        // 動画
        if (contents.category_name === "サイバー最新トレンド") {
          this.$router.push({
            name: "TrendSingle",
            params: { id: contents.id }
          });
        } else if (
          contents.category_name === "サイバー空間の脅威に立ち向かえ！"
        ) {
          this.$router.push({
            name: "FaceUpSingle",
            params: { id: contents.id }
          });
        } else if (contents.category_name === "サイバー関連サービス等解説コンテンツ") {
          this.$router.push({
            name: "BasicsSingle",
            params: { id: contents.id }
          });
        }
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({ name: "ToolSingle", params: { id: contents.id } });
      }
    },
    goMessage() {
      this.$router.push({name:'Message', params: { id: this.profile.chat_id }})
    },
  }
};
</script>

<style scoped>
@import "~@/css/mypage.css";

.vm--overlay {
  background: rgba(0, 0, 0, 0.9);
}
</style>